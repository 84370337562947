<template>
  <div>
    <div class="wrp">
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/write-download-1.png" class="banner" />
      <AppdownloadLittle></AppdownloadLittle>
    </div>
    <div class="wrp">
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-1.png" class="banner" />
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-2.png" class="banner" />
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-3.png" class="banner" />
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-4.png" class="banner" />
      <img src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-5.png" class="banner" />
      <div class="btnWrp">
        <div class="title">河小象美术APP</div>
        <button class="btn" @click="download" />
      </div>    
    </div>
  </div>
</template>
<script>
import AppdownloadLittle from "@/components/appdownloadLittle.vue";
export default {
  data() {
    return {
      
    };
  },
  components: { 
    AppdownloadLittle,
  },
  mounted() {
  },
  methods: {
    download() {
      window.location = 'https://sc2.hexiaoxiang.com/apk/meishu/hxx_meishu_2.1.0_xiaomi.apk'
    }  
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.wrp .banner {
  width: 100%;
  min-width: 1920px;
  min-height: 446px;
}

.btnWrp {
  position:absolute;
  top: 0;
  left: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.btnWrp .title {
  margin-top: 165px;
  font-family: PingFangSC-S0pxibold;
  font-weight: 600;
  font-size: 52px;
  color: #FFFFFF;
}

.btnWrp .btn {
  width: 184px;
  height: 50px;
  margin-top: 40px;
  background-image: url('https://sc2.hexiaoxiang.com/web_static/new_hx_web/art-download-btn.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
