<template>
  <div class="homeappwrpp">
    <div class="wrpp1">
      <div class="appmain">
        <div class="appcontainer">
          <p class="h1">学习软件支持各大平台</p>
          <p class="h3">随时随地，想学就学</p>
          <div class="download">
            <div class="phone">
              <p>微信小程序</p>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/mine.png" class="phonecode">
              <p class="phonetext">微信扫一扫，即可体验写字小程序</p>
            </div>
            <div class="phone">
              <p>苹果版</p>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ios.png" class="phonecode">
              <div class="phoneBtn" @click="handleCopy('https://itunes.apple.com/cn/app/%E6%B2%B3%E5%B0%8F%E8%B1%A1/id1436282840?mt=8',$event)">复制并分享给好友</div>
            </div>
            <div class="phone">
              <p>安卓版</p>
               <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/android.png" class="phonecode">
              <div class="phoneBtn" @click="handleCopy('https://android.myapp.com/myapp/detail.htm?apkName=com.mobcoreapp&ADTAG=mobile',$event)">复制并分享给好友</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clip from "../utils/clipboard";
export default {
  data() {
    return {};
  },
  mounted() {
    var that = this;
    that.passYes();
  },
  methods: {
    //向头部传参改变样式
    passYes() {
      this.$emit("changeHeader");
    },
    //去登陆，如果有key就是登陆成功了
    handleCopy(text, event) {
      clip(text, event);
      console.log("clicp");
    },
    goLogin() {
      let key = localStorage.getItem("key");
      if (!key) {
        this.$router.push({ path: "login" });
      } else {
        this.$router.push({ path: "classroom" });
      }
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;

  padding: 0;
}
.homeappwrpp {
  width: 100%;
  min-height: 100vh;
  display: flex;
  margin-top: 76px;
  justify-content: center;
  padding-top: 120px;
}
.qrcodeBox1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 100px 0;
}
.wrpp1 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.foot1 {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  background-color: #333333;
  justify-content: center;
}
.h1 {
  height: 56px;
  line-height: 56px;
  font-size: 56px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  margin-bottom: 10px;
}
.h3 {
  height: 26px;
  line-height: 26px;
  font-size: 26px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}
.phone {
  height: 346px;
  width: 300px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.phone1 {
  height: 346px;
  width: 300px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.phone p:first-child {
  font-size: 30px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}

.pc1 {
  font-size: 30px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}
.phonecode {
  margin-top: 42px;
  width: 200px;
  height: 200px;
}
.phoneBtn {
  width: 164px;
  height: 40px;
  background: rgba(19, 180, 254, 1);
  border-radius: 20px;
  font-size: 15px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
  margin-top: 19px;
}
.phonetext {
  margin-top: 19px;
  font-size: 20px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  width: 300px;
}
.phone p:last-child {
  font-size: 20px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  width: 300px;
}

.pc {
  margin-top: 42px;
  width: 356px;
  height: 356px;
}
.pc p {
  width: 120px;
  margin-top: 117px;
  font-size: 30px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
}

.download {
  width: 100%;
  height: 496px;
  margin-top: 112px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}
.appcontainer {
  width: 1200px;
  height: 695px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.appmain {
  width: 1200px;
  height: 631px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.pccode {
  margin-top: 44px;
  width: 142px;
  height: 127px;
}
.phonetext {
  margin-top: 19px;
  font-size: 20px;
  font-family: "MicrosoftYaHei";
  color: rgba(81, 85, 88, 1);
  width: 300px;
  cursor: pointer;
}
</style>
